import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { Flex, Box } from 'reflexbox'
import Badge from '../components/Badge'
import { ThemeConsumer } from 'styled-components'

const CompanyLogos = () => {
  const data = useStaticQuery(graphql`
    query {
      microsoft: file(relativePath: { eq: "logos/microsoft@2x.png" }) {
        childImageSharp {
          fixed(width: 118, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      microsoftDark: file(relativePath: { eq: "logos/microsoft-dark@2x.png" }) {
        childImageSharp {
          fixed(width: 118, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      intercom: file(relativePath: { eq: "logos/intercom@2x.png" }) {
        childImageSharp {
          fixed(width: 131, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      intercomDark: file(relativePath: { eq: "logos/intercom-dark@2x.png" }) {
        childImageSharp {
          fixed(width: 131, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      shopify: file(relativePath: { eq: "logos/shopify@2x.png" }) {
        childImageSharp {
          fixed(width: 116, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      shopifyDark: file(relativePath: { eq: "logos/shopify-dark@2x.png" }) {
        childImageSharp {
          fixed(width: 116, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      airbnb: file(relativePath: { eq: "logos/airbnb@2x.png" }) {
        childImageSharp {
          fixed(width: 128, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      google: file(relativePath: { eq: "logos/google@2x.png" }) {
        childImageSharp {
          fixed(width: 108, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      atlassian: file(relativePath: { eq: "logos/atlassian@2x.png" }) {
        childImageSharp {
          fixed(width: 172, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      atlassianDark: file(relativePath: { eq: "logos/atlassian-dark@2x.png" }) {
        childImageSharp {
          fixed(width: 172, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      salesforce: file(relativePath: { eq: "logos/salesforce@2x.png" }) {
        childImageSharp {
          fixed(width: 62, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      discord: file(relativePath: { eq: "logos/discord@2x.png" }) {
        childImageSharp {
          fixed(width: 131, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      bacardi: file(relativePath: { eq: "logos/bacardi@2x.png" }) {
        childImageSharp {
          fixed(width: 97, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <ThemeConsumer>
      {theme => (
        <Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
          className="center">
          <Box width={1} mb={[2]}>
            <h6>IconJar is used by people from:</h6>
          </Box>
          <Box width={[1 / 2, 1 / 3, 1 / 4]} my={3}>
            <Box style={{ width: '118px', maxWidth: '100%' }} mx={'auto'}>
              <Img
                fixed={
                  theme.appearance === 'light'
                    ? data.microsoft.childImageSharp.fixed
                    : data.microsoftDark.childImageSharp.fixed
                }
                alt="Microsoft logo"
              />
            </Box>
          </Box>
          <Box width={[1 / 2, 1 / 3, 1 / 4]} my={3}>
            <Box style={{ width: '131px', maxWidth: '100%' }} mx={'auto'}>
              <Img
                fixed={
                  theme.appearance === 'light'
                    ? data.intercom.childImageSharp.fixed
                    : data.intercomDark.childImageSharp.fixed
                }
                alt="Intercom logo"
              />
            </Box>
          </Box>
          <Box width={[1 / 2, 1 / 3, 1 / 4]} my={3}>
            <Box style={{ width: '128px', maxWidth: '100%' }} mx={'auto'}>
              <Img
                fixed={data.airbnb.childImageSharp.fixed}
                alt="Airbnb logo"
              />
            </Box>
          </Box>
          <Box width={[1 / 2, 1 / 3, 1 / 4]} my={3}>
            <Box style={{ width: '108px', maxWidth: '100%' }} mx={'auto'}>
              <Img
                fixed={data.google.childImageSharp.fixed}
                alt="Google logo"
              />
            </Box>
          </Box>
          <Box width={[1 / 2, 1 / 3, 1 / 4]} my={3} display={['none', 'block']}>
            <Box style={{ width: '172px', maxWidth: '100%' }} mx={'auto'}>
              <Img
                fixed={
                  theme.appearance === 'light'
                    ? data.atlassian.childImageSharp.fixed
                    : data.atlassianDark.childImageSharp.fixed
                }
                alt="Atlassian logo"
              />
            </Box>
          </Box>
          <Box width={[1 / 2, 1 / 3, 1 / 4]} my={3}>
            <Box style={{ width: '116px', maxWidth: '100%' }} mx={'auto'}>
              <Img
                fixed={
                  theme.appearance === 'light'
                    ? data.shopify.childImageSharp.fixed
                    : data.shopifyDark.childImageSharp.fixed
                }
                alt="Shopify logo"
              />
            </Box>
          </Box>
          <Box width={[1 / 2, 1 / 3, 1 / 4]} my={3}>
            <Box style={{ width: '86px', maxWidth: '100%' }} mx={'auto'}>
              <Img
                fixed={data.salesforce.childImageSharp.fixed}
                alt="Salesforce logo"
              />
            </Box>
          </Box>
          <Box width={[1 / 2, 1 / 3, 1 / 4]} my={3}>
            <Box style={{ width: '131px', maxWidth: '100%' }} mx={'auto'}>
              <Img
                fixed={data.discord.childImageSharp.fixed}
                alt="Discord logo"
              />
            </Box>
          </Box>
        </Flex>
      )}
    </ThemeConsumer>
  )
}

export default CompanyLogos
