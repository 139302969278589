import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import Img from 'gatsby-image'
import GradientMask from '../components/GradientMask'
import styled from 'styled-components'
import { ThemeConsumer } from 'styled-components'

// Icons
import IconRecentlyUsed from '../images/vector/icon-recent-small.svg'
import IconSmartSet from '../images/vector/icon-smartset.svg'
import IconStar from '../images/vector/icon-star.svg'

const StyledHighlightedFeature = styled(Box)`
  transition: opacity 0.15s ease-in-out;
  opacity: 0.65;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
`

const StyledHighlightedImage = styled(Img)`
  opacity: 0;
  transition: all 0.4s ease-in-out;

  &.active {
    opacity: 1;
  }
`

const MainHighlights = ({ props }) => {
  // Fetch images
  const data = useStaticQuery(graphql`
    query {
      smartSetsScreenshot: file(relativePath: { eq: "smartsets@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      smartSetsScreenshotDark: file(
        relativePath: { eq: "smartsets-dark@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      recentlyUsedScreenshot: file(
        relativePath: { eq: "recentlyused@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      recentlyUsedScreenshotDark: file(
        relativePath: { eq: "recentlyused-dark@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      starredScreenshot: file(relativePath: { eq: "starred@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      starredScreenshotDark: file(relativePath: { eq: "starred-dark@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  // State which controls which image to show
  let [highlighted, setHighlighted] = useState('SmartSets')

  return (
    <ThemeConsumer>
      {theme => (
        <Flex
          flexWrap="wrap"
          mb={[4, 5]}
          alignItems="center"
          flexDirection={['column-reverse', 'row']}>
          <Box width={[1, 1 / 2]} px={[0, 3]}>
            {/* Feature 1 (Smart sets) */}
            <StyledHighlightedFeature
              mb={[4, 5]}
              className={highlighted === 'SmartSets' ? 'active' : ''}
              onMouseOver={() => setHighlighted('SmartSets')}>
              <Box mb={3}>
                <IconSmartSet />
              </Box>
              <h4>Bundle similar icons with Smart Sets</h4>
              <p>
                Create Smart Sets in IconJar by bundling your favorite icons or
                those with similar characteristics. IconJar will take care of
                the hard work for you.
              </p>
            </StyledHighlightedFeature>

            {/* Feature 2 (Recently used) */}
            <StyledHighlightedFeature
              mb={[4, 5]}
              className={highlighted === 'RecentlyUsed' ? 'active' : ''}
              onMouseOver={() => setHighlighted('RecentlyUsed')}>
              <Box mb={3}>
                <IconRecentlyUsed />
              </Box>
              <h4>See recently used icons at a glance</h4>
              <p>
                IconJar automatically adds the icons you've used to a recently
                used set in the left sidebar.
              </p>
            </StyledHighlightedFeature>

            {/* Feature 3 (Starred) */}
            <StyledHighlightedFeature
              mb={4}
              className={highlighted === 'Starred' ? 'active' : ''}
              onMouseOver={() => setHighlighted('Starred')}>
              <Box mb={3}>
                <IconStar />
              </Box>
              <h4>Star your favourite icons</h4>
              <p>
                You can now star the icons you like the most. Starred icons are
                all grouped in one set, so you don't have to search when you
                need them.
              </p>
            </StyledHighlightedFeature>
          </Box>

          <Box width={[1, 1 / 2]} px={[0, 3]} mb={[4, 0]}>
            <GradientMask alignment="right">
              <StyledHighlightedImage
                fluid={
                  theme.appearance === 'light'
                    ? data.smartSetsScreenshot.childImageSharp.fluid
                    : data.smartSetsScreenshotDark.childImageSharp.fluid
                }
                alt="IconJar app - inspector"
                className={highlighted === 'SmartSets' ? 'active' : ''}
              />
              <StyledHighlightedImage
                fluid={
                  theme.appearance === 'light'
                    ? data.recentlyUsedScreenshot.childImageSharp.fluid
                    : data.recentlyUsedScreenshotDark.childImageSharp.fluid
                }
                alt="IconJar app - inspector"
                className={highlighted === 'RecentlyUsed' ? 'active' : ''}
                style={{ marginTop: '-71.57598499061913%' }}
              />
              <StyledHighlightedImage
                fluid={
                  theme.appearance === 'light'
                    ? data.starredScreenshot.childImageSharp.fluid
                    : data.starredScreenshotDark.childImageSharp.fluid
                }
                alt="IconJar app - inspector"
                className={highlighted === 'Starred' ? 'active' : ''}
                style={{ marginTop: '-71.57598499061913%' }}
              />
            </GradientMask>
          </Box>
        </Flex>
      )}
    </ThemeConsumer>
  )
}

export default MainHighlights
