import React from 'react'
import styled from 'styled-components'

const StyledMask = styled.div`
  @media (min-width: 640px) {
    width: 575px;
    transform: ${props =>
      props.alignment === 'left' ? 'translateX(-50%)' : '0'};
  }

  @media (min-width: 740px) {
    width: 700px;
    transform: ${props =>
      props.alignment === 'left' ? 'translateX(-50%)' : '0'};
  }

  @media (min-width: 1024px) {
    width: 970px;
    transform: ${props =>
      props.alignment === 'left' ? 'translateX(-50%)' : '0'};
    img {
      mask-image: ${props =>
        props.alignment === 'left'
          ? 'linear-gradient(to left, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0))'
          : 'linear-gradient(to right, rgba(0, 0, 0, 1) 25%, rgba(0, 0, 0, 0))'};
    }
  }
`

const GradientMask = props => {
  return <StyledMask {...props}>{props.children}</StyledMask>
}

export default GradientMask
