import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import Img from 'gatsby-image'
import GradientMask from '../components/GradientMask'
import styled from 'styled-components'
import { ThemeConsumer } from 'styled-components'

// Icons
import QuickDrag from '../images/vector/icon-quickdrag.svg'
import ExportIcons from '../images/vector/export-icons.svg'
import IconSvgCleaner from '../images/vector/svg-cleaner.svg'

const StyledHighlightedFeature = styled(Box)`
  transition: opacity 0.15s ease-in-out;
  opacity: 0.65;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
  }
`

const StyledHighlightedImage = styled(Img)`
  opacity: 0;
  transition: all 0.4s ease-in-out;

  &.active {
    opacity: 1;
  }
`

const MainHighlights = ({ props }) => {
  // Fetch images
  const data = useStaticQuery(graphql`
    query {
      dragDropScreenshot: file(relativePath: { eq: "dragdrop@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      dragDropScreenshotDark: file(
        relativePath: { eq: "dragdrop-dark@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      exportScreenshot: file(relativePath: { eq: "export@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      exportScreenshotDark: file(relativePath: { eq: "export-dark@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      optimizerScreenshot: file(relativePath: { eq: "optimizer@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      optimizerScreenshotDark: file(
        relativePath: { eq: "optimizer-dark@2x.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1151, quality: 85) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  // State which controls which image to show
  let [highlighted, setHighlighted] = useState('DragDrop')

  return (
    <ThemeConsumer>
      {theme => (
        <Flex flexWrap="wrap" mb={[4, 5]} alignItems="center">
          <Box width={[1, 1 / 2]} px={[0, 3]} mb={[4, 0]}>
            <GradientMask alignment="left">
              <StyledHighlightedImage
                fluid={
                  theme.appearance === 'light'
                    ? data.dragDropScreenshot.childImageSharp.fluid
                    : data.dragDropScreenshotDark.childImageSharp.fluid
                }
                alt="IconJar app - inspector"
                className={highlighted === 'DragDrop' ? 'active' : ''}
              />
              <StyledHighlightedImage
                fluid={
                  theme.appearance === 'light'
                    ? data.exportScreenshot.childImageSharp.fluid
                    : data.exportScreenshotDark.childImageSharp.fluid
                }
                alt="IconJar app - inspector"
                className={highlighted === 'Export' ? 'active' : ''}
                style={{ marginTop: '-71.57598499061913%' }}
              />
              <StyledHighlightedImage
                fluid={
                  theme.appearance === 'light'
                    ? data.optimizerScreenshot.childImageSharp.fluid
                    : data.optimizerScreenshotDark.childImageSharp.fluid
                }
                alt="IconJar app - inspector"
                className={highlighted === 'SVGOptimizer' ? 'active' : ''}
                style={{ marginTop: '-71.57598499061913%' }}
              />
            </GradientMask>
          </Box>
          <Box width={[1, 1 / 2]} px={[0, 3]}>
            {/* Feature 1 (Drag + drop) */}
            <StyledHighlightedFeature
              mb={[4, 5]}
              className={highlighted === 'DragDrop' ? 'active' : ''}
              onMouseOver={() => setHighlighted('DragDrop')}>
              <Box mb={3}>
                <QuickDrag />
              </Box>
              <h4>Drag icons into your apps in any size or color</h4>
              <p>
                Use IconJar alongside your design tool, code editor, word
                processor or presentation software and streamline your
                workflows.
              </p>
              <p>
                For example: Google Apps, Figma/Sketch and Visual Studio Code.
              </p>
            </StyledHighlightedFeature>

            {/* Feature 2 (Export) */}
            <StyledHighlightedFeature
              mb={[4, 5]}
              className={highlighted === 'Export' ? 'active' : ''}
              onMouseOver={() => setHighlighted('Export')}>
              <Box mb={3}>
                <ExportIcons />
              </Box>
              <h4>Bulk export icons in the sizes you need</h4>
              <p>
                Save time by using one of our built-in presets for Android, iOS,
                macOS or create custom presets that suit your needs.
              </p>
            </StyledHighlightedFeature>

            {/* Feature 3 (SVG optimizer) */}
            <StyledHighlightedFeature
              mb={4}
              className={highlighted === 'SVGOptimizer' ? 'active' : ''}
              onMouseOver={() => setHighlighted('SVGOptimizer')}>
              <Box mb={3}>
                <IconSvgCleaner />
              </Box>
              <h4>Optimize SVGs and make them production ready</h4>
              <p>
                Use our SVG optimizer to make icons production-ready. We have
                plenty of settings for this too!
              </p>
            </StyledHighlightedFeature>
          </Box>
        </Flex>
      )}
    </ThemeConsumer>
  )
}

export default MainHighlights
